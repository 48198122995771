
import './tom-select.min.css';
import './flatpickr.min.css';

import TomSelect from './tom-select.complete.min.js';
import Flatpickr from './flatpickr.min.js';

window.TomSelect = TomSelect;
window.Flatpickr = Flatpickr;

