import template from './ko-page-title.html';

class PageTitleComponentViewModel
{
	constructor (params)
	{
		this.params = params;
		this.current_page_title = window.current_page_title;
	}
}

export default {
	name: 'ko-page-title',
	viewModel: PageTitleComponentViewModel,
	module_type: 'ko',
	template: template
};
