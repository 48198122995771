// Helper function to create DOM element
function createElement(tag, attributes, ...children) {
	const element = document.createElement(tag);
	Object.keys(attributes).forEach(attr => element.setAttribute(attr, attributes[attr]));
	children.forEach(child => {
		if (typeof child === 'string')
			element.appendChild(document.createTextNode(child));
		else
			element.appendChild(child);
	});
	return element;
}

// Map user-provided type to class and icon
function getAlertClassAndIcon(type) {
	const mappings = {
		'warning': {class: 'ps-warning-class', icon: 'fa-exclamation-triangle'},
		'success': {class: 'ps-success-class', icon: 'fa-check'},
		'danger':  {class: 'ps-danger-class',  icon: 'fa-circle-exclamation'},
		'error':   {class: 'ps-error-class',   icon: 'fa-exclamation'},
		'info':    {class: 'ps-info-class',    icon: 'fa-info'},
	};

	return mappings[type] || {class: 'ps-default-class', icon: 'fa-info-circle'};
}

// Alert modal with close button
async function alert({type, title, message}) {
	return Grape.dialog.open('Alert', {title, message, ...getAlertClassAndIcon(type)});
}

// Prompt modal with confirm and cancel buttons (resolves with the input value or null)
async function prompt({type, title, message, fields}) {
	return Grape.dialog.open('AlertPrompt', {title, message, fields, ...getAlertClassAndIcon(type)});
}

// Confirm modal with yes and no buttons (resolves boolean based on button click)
async function confirm({type, title, message, accept_text, cancel_text, accept_type, cancel_type}) {
	return Grape.dialog.open('AlertConfirm', {title, message, accept_text, cancel_text, accept_type, cancel_type, ...getAlertClassAndIcon(type)});
}

// Alert modal with close button
async function apiError(api_error) {
	return Grape.dialog.open('AlertApiError', api_error);
}

export default {
	alert,
	prompt,
	confirm,
	apiError
};
