
import template from './visible-columns.html';

class ComponentViewModel
{
	constructor (params)
	{
		this.params = params;
		this.expanded = ko_helper.safe_observable(params.expanded || false);
		this.columns = ko_helper.safe_observableArray(params.columns || []);
		this.visible_columns = ko_helper.safe_observableArray(params.visible_columns || []);
	}

	on_click_item (column, event)
	{
		let current_visible_columns = this.visible_columns();
		let column_index = current_visible_columns.indexOf(column);
		if (column_index === -1)
			current_visible_columns.push(column);
		else
			current_visible_columns.splice(column_index, 1);

		this.visible_columns(current_visible_columns);
	}
}

export default {
	name: 'visible-columns',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
