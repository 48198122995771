
import template from './ko-render-field-list.html';

/**
 * @typedef
 * @name ListInputComponentParamsType
 * @description Input Type for component
 * @type Object
 * @param name
 * @param label
 * @param {Array | Object} items
 * @param list_label_field
 * @param list_value_field
 * @param {Boolean} multiple
*/

class ListInputComponentVM
{
	constructor(params)
	{
		this.params = params;

		//console.debug('list PARAMS=',params);

		this.name = ko.observable(params.name);
		this.label = ko.observable(params.label);
		this.$input = ko_helper.safe_observable(params.$input);

		this.options = ko.observableArray([]);
		this.optionsText = ko_helper.safe_observable(params.list_label_field, null);
		this.optionsValue = ko_helper.safe_observable(params.list_value_field, null);
		this.optionsCaption = ko_helper.safe_observable(params.caption, 'Select...');
		this.selectedOptions = ko.observableArray();
		this.canAdd = ko.observable(params.can_add || false); /* User can add entries that is not in the list */
		this.value = ko.observable();
		this.multiple = ko_helper.safe_observable(params.multiple, false);

		this.load_options();
	}

	async load_options()
	{
		if (this.params.hasOwnProperty('items') && Array.isArray(this.params.items))
		{
			let items = [];
			for (let i of this.params.items)
			{
				if (typeof i == 'string')
					items.push({text: i, value: i});
				else
					items.push(i);
			}
			if (!this.optionsText())
				this.optionsText('text');
			if (!this.optionsValue())
				this.optionsValue('value');
			this.options(items);
		}
		else if (this.params.hasOwnProperty('items_from_cache'))
		{
			let items;
			try {
				items = await Grape.cache.get(this.params.items_from_cache);
			} catch (err) {
				console.error(err);
			}
			if (!this.optionsText())
				this.optionsText('text');
			if (!this.optionsValue())
				this.optionsValue('value');
			this.options(items);
		}
		else if (this.params.fetch_items && this.params.fetch_items instanceof Function)
		{
			const items = await this.params.fetch_items(this);
			this.options(items);
		}

		if (this.multiple() === true)
		{
			this.selectedOptions(this.$input());
			this.optionsCaption(null);
		}
		else
		{
			this.value(this.$input());
			this.value.subscribe((value) => {
				this.$input(value);
			});
		}
	}
}


export default {
	name: 'ko-render-field-list',
	viewModel: ListInputComponentVM,
	module_type: 'ko',
	template: template
}

