/**
 * @kind ko binding handler
 * @name htmlFormatter
 * @description A ko binding handler that allow you to specify a function to use the $data context data to provide a return value for use as the innerHTML
 **/
ko.bindingHandlers.htmlFormatter = {
	update: async function(element, valueAccessor, allBindings, viewModel, bindingContext) {
		let value = ko.utils.unwrapObservable(valueAccessor());
		const bindings = allBindings();
		if (bindings.formatter && bindings.formatter instanceof Function)
			value = await bindings.formatter(value);

		if (value)
			element.innerHTML = value;
		else
			element.innerHTML = '';

	}
};

export default 'bindings';
