
import template from './Alert.html';

class AlertVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.title = ko.observable(dialog.bindings.title || '');
		this.message = ko.observable(dialog.bindings.message || '');
		this.type_class = ko.observable(dialog.bindings.class || '');
		this.icon = ko.observable(dialog.bindings.icon || '');
	}

	close(){
		this.dialog.close();
	}
}

class AlertDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AlertVM(this); // Name of the ViewModel defined above
		this.name = 'NewDialog'; // Unique dialog name
	}
}

export default {
	name: 'Alert',
	dialog_class: AlertDialog,
	provider: 'ps',
	template: template
}
