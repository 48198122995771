import template from './picker.html';

/**
* @class PickerViewModel
* @component name - picker
* @constructor
* @param {object[]} [available_items=[]]- optional param passed to the constructor to initialize available_items
* @param {object[]} selectedItem- selected item
* @param {text} [title] - title of the dropdown
*/
class PickerViewModel
{
	constructor (params)
	{
		this.params = params;

		this.refreshData = params.refreshData;
		this.displayFormatter = async item=>this.params.displayFormatter(item);
		this.available_items = ko.observableArray();
		this.selectedItem = ko_helper.safe_observable(params.selectedItem);
		this.title = ko.observable(params.title || '');
		this.searchText = ko.observable();
		this.searchText.subscribe((newValue) => this.search(newValue) );
		this.init();
	}

	async init ()
	{
		let items;

		items = await this.refreshData() || [];
		this.available_items(items);
	}

	async search (newValue)
	{
		this.available_items((await this.refreshData(newValue))||[]);
	}

	item_click (row)
	{
		this.searchText('');

		if (row === this.selectedItem())
			this.selectedItem('');
		else
			this.selectedItem(row);
	}

	pick(data, event){
		setTimeout(()=>{
			event.currentTarget.parentNode.querySelector('input').focus();
			event.currentTarget.parentNode.querySelector('input').select();
		}, 10);
	}
}

export default {
	name: 'picker',
	viewModel: PickerViewModel,
	module_type: 'ko',
	template: template
}
