import template from './ko-tabs-dashboard.html';

class TabsDashboardComponentViewModel
{
	constructor (params)
	{
		this.params = params;
		this.tabs_title = ko_helper.safe_observable(params.tabs_title || 'Tabs Dashboard');
		this.tabs_source = ko_helper.safe_observable(params.tabs_source);
		this.contact = ko_helper.safe_observable(params.contact);
		this.tabs = ko.observableArray([]);
		this.selected_tab = ko.observable();

		this.init();
	}

	init ()
	{
		let map = Grape.registry.getRegister(this.tabs_source());
	
		if (map && map.size)
		{
			this.tabs([...map.keys()].map(key => ({
				tab_component_name: key,
				tab_component: map.get(key)
			})));
			this.selected_tab([...map.keys()][0]);
		}
	}
}

export default {
	name: 'ko-tabs-dashboard',
	viewModel: TabsDashboardComponentViewModel,
	module_type: 'ko',
	template: template
}
