import template from './ps-datetime-picker.html';

/**
 * KO component for building a calendar
 * @kind component
 * @class ComponentViewModel
 */
class ComponentViewModel
{
	constructor(params, rootElement)
	{
		this.params = params;
		this.rootElement = rootElement;
		this.inputElement = rootElement.querySelector('input');
		this.returnOption = params.returnOption ??= true;
		this.classes = params.classes || [];
		this.settings = ko_helper.safe_observable(params.settings);
		this.settings.subscribe(() => {
			this.render();
		})

		this.value = ko.isObservable(params.value) ? params.value : ko.observable(params.value);
		this.flatpickr;
		this.render();

	}

	render()
	{
		if (this.flatpickr)
			this.flatpickr.destroy();

		const settings = new Function("return " + this.settings() + ";")();

		const mergedSettings = {
			enableTime: true,
			dateFormat: "Y/m/d H:i",
			...settings,
			defaultDate: this.value(),
			onChange: (selectedDates, dateStr, instance) => {
				this.value(dateStr);
			}
		};

		this.flatpickr = window.flatpickr(this.inputElement, mergedSettings);

		if (this.value())
		{
			this.flatpickr.setDate(this.value(), false);
		}

		// Subscribe to changes in the value observable
		this.value.subscribe((newValue) => {
			if (newValue !== this.flatpickr.selectedDates[0])
			{
				this.flatpickr.setDate(newValue, false);
			}
		});
	}
}

export default {
	name: 'ps-datetime-picker',
	viewModel: ComponentViewModel,
	provider: 'ko',
	template: template
};
