
import template from './ko-render-field-password.html';

/**
 * ViewModel for PasswordInputComponent.
 * 
 * @class PasswordInputComponentVM
 * @param {Object} params - The parameters for this ViewModel.
 * @param {string} params.name - The name of the password input component.
 * @param {string} params.label - The label of the password input component.
 * @param {string} params.$input - The input element for the password input component.
 */
class PasswordInputComponentVM
{
	constructor(params)
	{
		this.params = params;
		this.name = ko.observable(params.name);
		this.label = ko.observable(params.label);
		this.$input =params.$input;
	}
}


export default {
	name: 'ko-render-field-password',
	viewModel: PasswordInputComponentVM,
	module_type: 'ko',
	template: template
}

