
class DialogProvider
{
	static async show(dialog_definition, bindings, display_options)
	{
		let instance; // Dialog class instance
		const { element, dialog } = dialog_definition;
		const dialog_element = window.document.createElement('dialog');

		// move all child elements added by grape.dialog inside of dialog element
		// TODO maybe grape.dialog should allow providers to do more of the work?
		while(element.hasChildNodes()) dialog_element.appendChild(element.firstChild);

		element.appendChild(dialog_element);

		// Prevent background scrolling
		window.document.body.style.overflow = 'hidden';

		// Show dialog
		element.classList.add('ps');
		element.style.display = 'block';
		window.document.body.appendChild(element);

		try {
			if (dialog.dialog_class)
			{
				try {
					instance = new dialog.dialog_class(bindings, dialog_element);
				} catch (e) {
					console.error(e);
				}
			}

			if (dialog.pageClass)
			{
				try {
					instance = new dialog.pageClass(bindings, dialog_element);
				} catch (e) {

					console.error(e);
				}
			}
		} catch (e) {
			console.error('Error on dialog_class instantiation');
			console.error(e);
			instance = new Object();
		}

		if (instance.hasOwnProperty('viewModel'))
		{
			try {
				window.ko.applyBindings(instance.viewModel, dialog_element);
			} catch (e) {
				console.error('Error while applying bindings vm:', instance.viewModel, 'target:', dialog_element);
				console.error(e);
			}
		}

		if (instance?.init && typeof instance.init === 'function')
		{
			try {
				instance.init();
			} catch (e) {
				console.error('Error while running dialog init');
				console.error(e);
			}
		}

		if (instance?.updateData && typeof instance.updateData === 'function')
		{
			try {
				instance.updateData();
			} catch (e) {
				console.error('Error while running dialog updateData');
				console.error(e);
			}
		}

		dialog_element.showModal();
		let close_event_func = (event) => {
			instance.close();
		};
		dialog_element.addEventListener("close", close_event_func);

		instance.close = async (data) => {
			if (instance.status === 'closing')
				return;
			dialog_element.removeEventListener("close", close_event_func);
			instance.status = 'closing';
			if (instance.hasOwnProperty('beforeClose') && instance.beforeClose instanceof Function)
			{
				await instance.beforeClose.call(instance, data);
			}

			dialog_element.close();

			setTimeout((event)=>{
				window.ko.cleanNode(dialog_element);
				element.style.display = 'none';
				window.document.body.removeChild(element);
				
				// Restore background scrolling
				window.document.body.style.overflow = '';
			},200);//wait for animations before removing
			// TODO find a better way of handling this?

			if (display_options.hasOwnProperty('onClose') && display_options.onClose instanceof Function)
			{
				display_options.onClose.call(instance, data);
			}
		};
	}
}

export default DialogProvider;
