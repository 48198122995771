import template from './template.html';

const MSG_INVALID_PAGE = 'on_page_click is invalid';

/**
 * Component VM for data-pagination control
 */
class ComponentVM {
	/**
	 * Default class constructor
	 * @param {object} params Knockout parameters used for instantiation of the component
	 * @param {integer|observable} params.page_number The current page number. Preferably an observable of an integer field
	 * @param {integer|observable} params.page_count  The number of total pages. Preferably an observable of an integer field
	 * @param {integer|observable}  params.max_pages  The maximum number of pages to display.
	 * @param {function|observable} params.page_click The callback to use whenever a page is selected. Syntax: page_click(page_number)
	 *
	 */
	constructor(params) {
		this.page_number = ko_helper.safe_observable(params.page_number, 1);
		this.page_count = ko_helper.safe_observable(params.page_count, 1); // TODO rename to total_pages (align with GetRecords)
		this.max_pages = ko_helper.safe_observable(params.max_pages, 15);

		// Utility variables to disable the previous / next buttons
		this.block_previous = ko.pureComputed((val) => { return this.page_number() <= 1; });
		this.block_next = ko.pureComputed((val) => { return this.page_number() >= this.page_count(); });

		// Calculate which pages to display
		this.page_list = ko.pureComputed(() => {
			return this.get_page_list();
		});

		this.on_page_click = params.page_click;
	}

	/**
	 * Calculate which page numbers should be shown
	 */
	get_page_list() {
		const page_number = this.page_number();
		const max_pages = this.max_pages();
		const page_count = this.page_count();
		const page_variance = Math.round((max_pages - 1) / 2, 0);

		let from_page = Math.max(1, page_number - page_variance);
		let to_page = from_page + max_pages - 1;

		if (to_page > page_count)
		{
			from_page = Math.max(1, from_page - to_page + page_count);
			to_page = page_count;
		}

		let page_list = [];
		for (let i = from_page; i <= to_page; i++)
		{
			page_list.push(i);
		}
		return page_list;
	}

	/**
	 * Event handler called when clicking on a page number
	 * @param {integer} page_number The page number that was selected
	 */
	page_click(page_number) {
		if (page_number < 1) page_number = 1;
		if (page_number > this.page_count()) page_number = this.page_count();
		if (page_number == this.page_number()) return; // Do nothing if already on the page

		if (this.on_page_click instanceof Function)
		{
			this.on_page_click(page_number);
		}
		else
		{
			this.page_number(page_number);
		}
	}
}

export default {
	name: 'data-pagination',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
