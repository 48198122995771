class ThemePlugin
{
	constructor(Grape)
	{
		this.Grape = Grape;
		this.theme = ko.observable();
		this.mode = ko.observable();
		this.default_theme;
		this.default_mode;

		if (window)
			window.ThemePlugin = this;
		
		Grape.themes = this;
	}

	async onInit()
	{
		Grape.registry.addRegister('Themes');
		Grape.registry.addItem('Themes', 'Default', {'class-name': 'default'});
	}

	setThemeDefaults (theme = 'default', mode = 'light')
	{
		this.default_theme = theme;
		this.default_mode = mode;
	}

	onLoad()
	{
		this.loadSystemTheme();
	}

	onSessionChange()
	{
		this.loadSystemTheme();
	}

	//Set Theme
	loadSystemTheme()
	{
		this.setTheme(`${this.getTheme()}`, `${this.getMode()}`);
	}

	registerThemes (themes)
	{
		for (let theme of themes)
			Grape.registry.addItem('Themes', theme.name, { 'class-name': theme.className });
	}

	getThemes ()
	{
		let themes = Grape.registry.getRegister('Themes');

		let theme_list = [];
		for (let [name, className] of themes)
			theme_list.push(className['class-name']);
		
		return theme_list;
	}

	setUserTheme (name, mode)
	{
		Grape.userSetting.updateUserSetting('theme', name);
		Grape.userSetting.updateUserSetting('theme_mode', mode);
		Grape.userSetting.set_value('theme', name);
		Grape.userSetting.set_value('theme_mode', mode);

		this.setTheme(name, mode);
	}

	setTheme (name = 'default', mode = 'light')
	{
		console.log(`[Setting Theme] = ${name}-${mode}`);
		this.theme(name);
		this.mode(mode);

		let el = document.querySelector('html');
		el.classList.forEach(c => {
			if (c.startsWith('theme-'))
				el.classList.remove(c);
		});

		if (name)
			el.classList.add(`theme-${name}-${mode}`);
	}

	getThemeClassFromName (name)
	{
		let themes = Grape.registry.getRegister('Themes');

		console.log('Themes: ', themes);
		let themeClass;
		for (let [themeName, className] of themes)
			if (name == themeName)
				themeClass = className['class-name']

		return themeClass;
	}

	getMode ()
	{
		//TODO find a getter to retrieve the up to date user settings
		let current_user_settings = Grape.currentSession?.user?.settings ;
		let selected_theme_mode = this.default_mode;

		if (current_user_settings?.theme_mode)
			selected_theme_mode = current_user_settings.theme_mode;
		else 
		{
			//Check System preference 
			let user_prefers_dark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

			if (user_prefers_dark)
				selected_theme_mode = 'dark';
		}

		return selected_theme_mode;
	}

	getTheme()
	{
		//TODO find a getter to retrieve the up to date user settings
		let current_user_settings = Grape.currentSession?.user?.settings ;
		let selected_theme = current_user_settings?.theme || Grape?.config?.public_settings?.theme || this.default_theme;

		console.log('Theme: ' + selected_theme);
		return selected_theme;
	}

	async updateUserSetting (setting_name, value)
	{
		try 
		{
			if (Grape.currentSession.user.settings.hasOwnProperty(setting_name))
				Grape.currentSession.user.settings[setting_name] = value;
			
			let response = await Grape.fetches.postJSON('/api/user-setting', {
				"setting_name": setting_name,
				"value":`"${value}"`
			});

			if (response.status !== 'OK') 
				throw new Error(response.message || response.status);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
}

export default ThemePlugin;