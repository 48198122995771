import template from './ps-upload-bar.html';

class PSUploadBarVM 
{
	constructor (params)
	{
		this.params = params;

		this.id = window.Grape.utils.generate_hash_id(32);
		this.form_id = this.id + "_form";

		this.button_busy = ko.observable(false);
		this.button_text = ko.observable('Upload');
		this.filename = ko.observable();

		this.filter = params.filter;
		this.url = params.url || '/grape/data_upload';
		this.placeHolderText = ko_helper.safe_observable(params.placeHolderText || 'Upload');
		this.description  = params.description;
		this.before_upload = params.before_upload;
		this.after_upload = params.after_upload;

		this.button_disabled = ko.pureComputed(() => {
			let filename = this.filename() || "";
			return filename.length === 0;
		});
	}

	file_selected (v, e, x)
	{
		let file_upload = document.getElementById(this.id);
		this.filename(file_upload.value
			.split('\\').pop().split('/').pop() // Get filename only
		);
	}

	browse ()
	{
		document.getElementById(this.id).click();
	}

	upload (a, v)
	{
		if (this.button_disabled())
		{	
			Grape.alerts.alert({
				type: 'warning',
				title: 'Warning',
				message: 'Please browse and select a file first!'
			});
			return;
		}

		// Prepare submission data
		let formData = new FormData(document.getElementById(this.form_id));
		let xhr = new XMLHttpRequest();

		// Register what to do after upload
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4)
			{
				let response_json;
				if (xhr.response === '')
				{
					response_json = {
						status: 'ERROR',
						message: 'Upload failure. Please send the file to support@platsoft.net along with what time you started the upload'
					};
				}
				else
					response_json = JSON.parse(xhr.response);

				if (response_json.status === 'ERROR')
				{
					Grape.alerts.alert({
						type: 'error',
						title: 'Upload Failure',
						message: response_json.message
					});
				}

				if (this.after_upload instanceof Function)
					this.after_upload(response_json);

				this.filename(null);
				this.button_text ('Upload');
				this.button_busy(false);
			}
		};

		if (this.before_upload instanceof Function)
			this.before_upload(formData, this);

		// Send data
		this.button_busy(true);
		this.button_text('Uploading ...');

		xhr.open('post', ko.utils.unwrapObservable(this.url), true);
		if (Grape.currentSession && Grape.currentSession.session_id)
			xhr.setRequestHeader('X-SessionID', Grape.currentSession.session_id);

		xhr.setRequestHeader('Accept', 'application/json');
		xhr.send(formData);
	}
}

export default {
	name: 'ps-upload-bar',
	viewModel: PSUploadBarVM,
	module_type: 'ko',
	template: template
};
