
import template from './ko-render-field-integer.html';

class IntegerInputComponentVM
{
	constructor(params)
	{
		this.params = params;

		//console.debug('INTEGER PARAMS=',params);

		// TODO min, max, step, placeholder

		this.name = ko.observable(params.name);
		this.label = ko.observable(params.label);
		this.rows = ko.observable(params.rows || 5);
		this.$input = params.$input;
	}
}


export default {
	name: 'ko-render-field-integer',
	viewModel: IntegerInputComponentVM,
	module_type: 'ko',
	template: template
}

