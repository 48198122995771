
import template from './AlertPrompt.html';

class AlertPromptVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.title = ko.observable(dialog.bindings.title || '');
		this.message = ko.observable(dialog.bindings.message || '');
		this.type_class = ko.observable(dialog.bindings.class || '');
		this.icon = ko.observable(dialog.bindings.icon || '');
		this.fields = ko.observable(dialog.bindings.fields || [{name:'prompt', type:'text', label:''}]);
		this.inputs = ko.observable({});
	}

	submit(){
		this.dialog.close(ko.mapping.toJS(this).inputs);
	}

	cancel(){
		this.dialog.close(false);
	}
}

class AlertPromptDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AlertPromptVM(this); // Name of the ViewModel defined above
		this.name = 'NewDialog'; // Unique dialog name
	}
}

export default {
	name: 'AlertPrompt',
	dialog_class: AlertPromptDialog,
	provider: 'ps',
	template: template
}
