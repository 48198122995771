/**
 * @kind ko binding handler
 * @name dataToggle
 * @description A Knockout.js binding handler that replaces Bootstrap's data-toggle="dropdown" using vanilla HTML and JavaScript.
**/
ko.bindingHandlers.dataToggle = {
	init: function(element)
	{
		let button = element.children[0];
		let dropdown_menu_ul = button.nextElementSibling;

		dropdown_menu_ul.style.display = 'none';

		function toggle_dropdown()
		{
			dropdown_menu_ul.style.display = (dropdown_menu_ul.style.display === 'none' ? 'block' : 'none');
		}

		function close_dropdown(event)
		{
			if (!element.contains(event.target))
				dropdown_menu_ul.style.display = 'none';
		}

		button.addEventListener('click', function(event) {
			event.stopPropagation();
			toggle_dropdown();
		});

		document.addEventListener('click', close_dropdown);
	}
};

export default 'bindings';
