
import template from './fields-filter.html';

/**
 * KO component for building filter based on fields
 * @kind component
 * @class FieldsSelector
 */
class ComponentViewModel
{
	constructor(params)
	{
		this.params = params;

		this.expanded = ko_helper.safe_observable(params.expanded || false);
		this.fields = ko_helper.safe_observableArray(params.fields || []);
		this.filters = ko_helper.safe_observableArray(params.filters || []);
		this.newFilter = ko_helper.safe_observable({
			field: ko.observable(),
			operator: ko.observable(''),
			value: ko.observable('')
		});
		this.operators = ko.observableArray(['=', '>', '>=', '<', '<=', '!=']);
	}
	
	addFilter_click()
	{
		let f = this.filters();
		f.push({
			field: this.newFilter().field(),
			operator: this.newFilter().operator(),
			value: this.newFilter().value()
		});
		this.filters(f);

		this.newFilter().field(null);
		this.newFilter().operator(null);
		this.newFilter().value(null);
	}
	
	removeFilter_click(filter)
	{
		let filters = [];
		for (let f of this.filters())
			if (!(
				f.field == filter.field &&
				f.operator == filter.operator &&
				f.value == filter.value
			))
			{
				filters.push(f);
			}
		this.filters(filters);
	}
}

export default {
	name: 'fields-filter',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};

