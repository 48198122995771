
import template from './RenderFields.html';

class RenderFieldsVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.title = ko.observable(dialog.bindings.title || '');
		this.type_class = ko.observable(dialog.bindings.class || '');
		this.icon = ko.observable(dialog.bindings.icon || '');

		this.inputs = ko.observable({});
		this.fields = ko.observableArray([]);
	}

	btnSave()
	{
		let data = {};
		for (let [name, $input] of Object.entries(this.inputs()))
			data[name] = ($input)();
		this.dialog.close(data);
	}

	btnClose()
	{
		this.dialog.close();
	}
}

class RenderFieldsDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new RenderFieldsVM(this); // Name of the ViewModel defined above
		this.name = 'RenderFieldsDialog'; // Unique dialog name
		
		if (this.bindings.fields)
		{
			this.viewModel.fields(fields);
		}
	}
}

export default {
	name: 'RenderFields',
	dialog_class: RenderFieldsDialog,
	provider: 'ps',
	template: template
}
