
import template from './tom-select.html';

/**
 * KO component for building filter based on fields
 * @kind component
 * @class FieldsSelector
 */
class ComponentViewModel
{
	constructor(params, rootElement)
	{
		this.params = params;
		this.rootElement = rootElement;
		this.inputElement = rootElement.querySelector('input');
		this.returnOption = params.returnOption ??= true;
		this.classes = params.classes||[];
		this.settings = ko_helper.safe_observable(params.settings);
		this.settings.subscribe(()=>{
			this.render();
		})
		this.value = ko_helper.safe_observable(params.value);
		this.id = ko.observable();

		this.tomSelect;
		this.render();
		this.id.subscribe((newValue)=>{
			let value;
			if (this.returnOption)
				value = this.tomSelect.options[newValue];
			else
				value = newValue;

			this.value(value);
		});

		this.value.subscribe((newValue)=>{
			let value;
			if (this.returnOption)
				value = newValue[this.tomSelect.settings.valueField];
			else
				value = newValue;

			if (value == this.id())
				return;

			this.tomSelect.setValue(value);
		});
	}

	getValueObject(){
		return this.tomSelect.options[this.tomSelect.getValue()];
	}

	render(){
		if (this.tomSelect)
			this.tomSelect.destroy();

		const settings = ko.toJS(this.settings());

		this.tomSelect = new window.TomSelect(this.inputElement, settings);
		this.tomSelect.on("change", (newValue)=>{
			this.id(newValue);
		});
		this.tomSelect.setValue(this.id());
	}

}

export default {
	name: 'tom-select',
	viewModel: ComponentViewModel,
	provider: 'ko',
	template: template
};
