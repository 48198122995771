
// COMPONENTS
import integer from './ko-render-field-integer/ko-render-field-integer.js';
import list from './ko-render-field-list/ko-render-field-list.js';
import password from './ko-render-field-password/ko-render-field-password.js';
import text from './ko-render-field-text/ko-render-field-text.js';
import boolean from './ko-render-field-boolean/ko-render-field-boolean.js';
import json from './ko-render-field-json/ko-render-field-json.js';
import object from './ko-render-field-object/ko-render-field-object.js';
import multiselect from './ko-render-field-multiselect/ko-render-field-multiselect.js';

import render_fields from './ko-render-fields/ko-render-fields.js';

export default [
	boolean,
	integer,
	list,
	password,
	text,
	json,
	render_fields,
	object,
	multiselect
];
