/**
 * FUNCTION: Generate HTML containing form elements based on JSON passed in bindings request parameters
 * Builds a form with input 
 * -------------------------------------------- 
 *  
 * EXAMPLE: Request Parameters that can be sent
 * --------------------------------------------
 * fields: 
 * [
 *  {
 *  	name: 
 * 	type: 
 * 		'text', 
 *		'integer', 
 *		'list', 
 *		'date', 
 *		'month' 
 *
 *	list: [] 		(optional - used when type is 'list')
 *	list_label_field:	if list contains objects, this field will be displayed
 *	list_value_field:	if list contains objects, this field will be used as the value
 *
 * 	label: 			(optional), 
 *	date_range: { start: date, end: date }		(used when type is 'month' or 'date' to give a range)
 *	month_range: [0, 12]				(used when type is month)
 *  }
 * ]
 * data: {} -- object to use as initial input - any extra fields will be returned as is
 */

import template from './ps_data_input.html';

//-------
//DIALOG: Renders form input controls based on JSON provided
class DataInputViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;

		this.title = ko.observable(this.dialog.bindings.title || 'Input Dialog');
		this.message = ko.observable(this.dialog.bindings.message || '');
		this.width = ko.observable(this.dialog.bindings.width || '');
		this.button_save_text = ko.observable(this.dialog.bindings.button_save_text || 'Save');
		this.header_icon = ko.observable(this.dialog.bindings.header_icon || 'fa fa-align-left');
		this.data = ko.observable(this.dialog.bindings.data || {});
		this.fields = ko.observable(this.dialog.bindings.fields);
	}

	onShow ()
	{
		let fields = this.fields();
		let data = this.dialog.bindings.data || {};

		fields.forEach((f) => {
			if (f.default_value == '' && data[f.name])
				f.default_value = data[f.name];
		});
		
		this.fields(fields);
	}

	btn_save_click ()
	{
		this.dialog.close(ko.mapping.toJS(this).data);
	}

	btn_close_click ()
	{
		this.dialog.close();
	}
}

class DataInputDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new DataInputViewModel(this);
		this.name = 'PsDataInput';
	}
}

export default {
	name: 'PsDataInput',
	dialog_class: DataInputDialog,
	provider: 'ps',
	template: template
};