
import template from './fields-selector.html';

/**
 * KO component for displaying and selecting fields
 * @kind component
 * @class FieldsSelector
 */
class ComponentViewModel
{
	constructor(params)
	{
		this.params = params;
		this.data = ko_helper.safe_observable(params.data);
		this.expanded = ko_helper.safe_observable(params.expanded || false);
		this.fields = ko_helper.safe_observableArray(params.fields || []);
		this.visible_field_names = ko_helper.safe_observableArray(params.visible_fields || []);

		this.use_fields = ko.observable([]);
		this.update();
		this.fields.subscribe(()=>{this.update()});

		this.onClickItem = (data, event)=>{
			data.observable(!data.observable());
			this.fields.valueHasMutated();
		}
		this.resetFields = params.resetFields;
	}

	moveUp(index, fields){
		if (index <= 0)
		 	return;
		this.fields.splice(index-1, 2, fields[index], fields[index-1]);
	}

	moveDown(index, fields){
		if (index >= fields.length-1)
		 	return;
		this.fields.splice(index, 2, fields[index+1], fields[index]);
	}

	update(){
		let checks = [];
		let vfn = [];
		for (let f of this.fields())
		{
			let check = {observable: ko.observable(f.visible()), field: f};
			check.observable.subscribe((nv) => {
				f.visible(nv);
				let vfn = this.visible_field_names();
				vfn.splice(vfn.indexOf(f.name), 1);
				this.visible_field_names(vfn);
			});

			if (f.visible())
				vfn.push(f.name);
			checks.push(check);
		}
		this.visible_field_names(vfn);
		this.use_fields(checks);
	}
}

export default {
	name: 'fields-selector',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
