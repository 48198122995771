
import template from './ko-render-field-json.html';

/**
 * ViewModel for JSONInputComponent.
 * 
 * @class JSONInputComponentVM
 * @type Object
 * @param {Object} params - The parameters for this ViewModel.
 * @param {string} params.name - The name of the JSON input component.
 * @param {string} params.label - The label of the JSON input component.
 * @param {boolean} [params.multiline=false] - Flag to indicate if the input should be multiline.
 * @param {number} [params.rows=5] - The number of rows for multiline input.
 * @param {string} [params.default_value=''] - The default value of the text input.
 */
class JSONInputComponentVM
{
	constructor(params)
	{
		this.params = params;

		this.name = ko.observable(params.name);
		this.label = ko.observable(params.label);
		this.multiline = ko.observable(params.multiline || false);
		this.rows = ko.observable(params.rows || 5);
		this.textInput = ko.observable(params.default_value || '');
		this.$input = params.$input;
		this.valid = ko.observable(true);
		
		this.textInput.subscribe((nv) => {
			let valid;
			let obj;
			try {
				obj = JSON.parse(nv);
				valid = true;
			} catch (err) {
				valid = false;
			}
			this.valid(valid);

			if (valid)
			{
				this.$input(obj);
			}
		});
	}
}


export default {
	name: 'ko-render-field-json',
	viewModel: JSONInputComponentVM,
	module_type: 'ko',
	template: template
}

