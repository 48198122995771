
import template from './ko-render-fields.html';

/**
 * Render fields knockout component class
 * @kind component
 * @class SampleComponent
 * @description Sample Component that shows the name value
 */
class ComponentViewModel
{
	constructor(params)
	{
		this.params = params;
		this.fields = ko_helper.safe_observableArray(params.fields); // An array of fields to display
		this.inputs = ko_helper.safe_observable(params.inputs, {}); // An observable Object containing the input observables for each field.
		this.initial_values = ko_helper.safe_observable(params.initial_values, {}); // An observable Object containing the initial values.
		
		this._render_fields = ko.observableArray(); // Array used by this component
		
		this.fields.subscribe((fields) => {
			this.renderFields();
		});

		if (this.fields().length)
			this.renderFields();
	}

	renderFields()
	{
		const fields = this.fields();
		let newfields = [];
		const inputs = {};
		const ivals = this.initial_values();

		for (let field of (fields || []))
		{
			if (!field.name)
			{
				console.error(new Error('No "name" field for field'));
				continue;
			}
			
			const obj = {
				component_name: this.getComponentForFieldType(field.type),
				params: Object.assign({}, field, {
					label: field.label ?? field.name,
					fields_component: this,
					$input: null
				})
			};

			if (!obj.component_name)
			{
				console.warn(new Error(`No component for field with type=${field.type}`));
				continue;
			}

			if (field.$input && ko.isObservable(field.$input))
				obj.params.$input = field.$input;
			else
				obj.params.$input = ko.observable();

			if (ivals.hasOwnProperty(field.name))
				obj.params.$input(ivals[field.name]);
			else if (field.default_value)
				obj.params.$input(field.default_value);

			newfields.push(ko.observable(obj));
			inputs[field.name] = obj.params.$input;
		}
		this._render_fields(newfields);
		this.inputs(inputs);
	}

	getComponentForFieldType(name)
	{
		const FieldType = Grape.registry.getItem('RenderFieldsFieldTypes', name);
		if (!FieldType)
		{
			console.error(new Error('Unknown render field type "' + name + '"'));
			return null;
		}
		return FieldType.component;
	}
}

export default {
	name: 'ko-render-fields',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
