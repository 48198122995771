
import components from './components/index.js';

window.Grape.registry.addRegister('RenderFieldsFieldTypes');
window.Grape.registry.addItem('RenderFieldsFieldTypes', 'text', {component: 'ko-render-field-text'});
window.Grape.registry.addItem('RenderFieldsFieldTypes', 'text_readonly', {component: 'ko-render-field-text'});
window.Grape.registry.addItem('RenderFieldsFieldTypes', 'json', {component: 'ko-render-field-json'});
window.Grape.registry.addItem('RenderFieldsFieldTypes', 'password', {component: 'ko-render-field-password'});
window.Grape.registry.addItem('RenderFieldsFieldTypes',
	'list',
	{
		component: 'ko-render-field-list',
		configuration: [
			{type: 'boolean', name: 'multiple'},
			{type: 'text', name: 'list_label_field'},
			{type: 'text', name: 'list_value_field'},
			{type: 'text', name: 'caption'},
			{
				type: 'list',
				name: 'items',
				items: [],
				can_add: true
			}
		]
	});
window.Grape.registry.addItem('RenderFieldsFieldTypes', 'integer', {component: 'ko-render-field-integer'});
window.Grape.registry.addItem('RenderFieldsFieldTypes', 'boolean', {component: 'ko-render-field-boolean'});
window.Grape.registry.addItem('RenderFieldsFieldTypes', 'object', {component: 'ko-render-field-object'});
window.Grape.registry.addItem('RenderFieldsFieldTypes', 'multiselect', {component: 'ko-render-field-multiselect'});

window.Grape.component.registerComponents(components);
