
import template from './AlertApiError.html';

class AlertApiErrorVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.api_error = ko.observable(dialog.bindings);
	}

	close(){
		this.dialog.close();
	}
}

class AlertApiErrorDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AlertApiErrorVM(this); // Name of the ViewModel defined above
		this.name = 'AlertApiError'; // Unique dialog name
	}
}

export default {
	name: 'AlertApiError',
	dialog_class: AlertApiErrorDialog,
	provider: 'ps',
	template: template
}
