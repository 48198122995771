import template from './ko-render-field-multiselect.html';

/**
 * @typedef
 * @name MultiselectInputComponentParamsType
 * @description Input Type for component
 * @type Object
 * @param {string} name
 * @param {string} label
 * @param {Array | Object} list
 * @param {string | Object} $input - selected values from 
 * @param multiselect_label_field
 * @param multiselect_value_field
 * @param {string} items_from_cache - get all items from cache
 * @param {string} fetch_items - 
 * @param {Boolean} multiple 
*/

class MultiselectInputComponentVM
{
	constructor(params)
	{
		this.params = params;

		this.name = ko.observable(params.name);
		this.label = ko.observable(params.label);
		this.$input = ko_helper.safe_observableArray(params.$input);

		this.options = ko.observableArray([]);
		this.optionsText = ko.observable();
		this.optionsValue = ko.observable();
		this.value = ko.observable();

		this.load_options();
	}

	async load_options()
	{
		if (this.params.hasOwnProperty('list') && Array.isArray(this.params.list))
		{
			let items = [];
			for (let i of this.params.list)
			{
				if (typeof i == 'string')
					items.push({text: i, value: i});
				else
					items.push(i);
			}
			if (!this.optionsText())
				this.optionsText('text');
			if (!this.optionsValue())
				this.optionsValue('value');
			this.options(items);
		}
		else if (this.params.hasOwnProperty('items_from_cache'))
		{
			let items;
			try {
				items = await Grape.cache.get(this.params.items_from_cache);
			} catch (err) {
				console.error(err);
			}
			if (!this.optionsText())
				this.optionsText('text');
			if (!this.optionsValue())
				this.optionsValue('value');
			this.options(items);
		}
		else if (this.params.fetch_items && this.params.fetch_items instanceof Function)
		{
			const items = await this.params.fetch_items(this);
			this.options(items);
		}
	}
}

export default {
	name: 'ko-render-field-multiselect',
	viewModel: MultiselectInputComponentVM,
	module_type: 'ko',
	template: template
}