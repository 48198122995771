function fieldNameHeading (text)
{
	return text.split('_').map((x) => { return x[0].toUpperCase() + x.slice(1); }).join(' ');
}

/**
 * @kind ko binding handler
 * @name fieldNameHeading
 * @description A ko binding handler that changes a field name into a friendly-looking heading, changes all underscores to spaces and capitalize words
 **/
ko.bindingHandlers.fieldNameHeading = {

	/*
	init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
		valueAccessor()(bindingContext.$data).then((text)=>{
			element.innerHTML = fieldNameHeading(text);
		});
	},
	*/
	update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
		const value = ko.utils.unwrapObservable(valueAccessor());
		if (value)
			element.innerHTML = fieldNameHeading(value);
		else
			element.innerHTML = '';
		
	}
};

export default 'bindings';
