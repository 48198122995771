
import template from './ko-render-field-boolean.html';

class BooleanInputComponentVM
{
	constructor(params)
	{
		this.params = params;
		this.name = ko.observable(params.name);
		this.label = ko.observable(params.label);
		this.$input = params.$input;
	}
}


export default {
	name: 'ko-render-field-boolean',
	viewModel: BooleanInputComponentVM,
	module_type: 'ko',
	template: template
}
