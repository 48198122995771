import template from './ps-multiple-datepicker.html';

/**
 * KO component for building a calendar with multiple date selection
 * @kind component
 * @class ComponentViewModel
 */
class ComponentViewModel
{
	constructor(params, rootElement)
	{
		this.params = params;
		this.rootElement = rootElement;
		this.inputElement = rootElement.querySelector('input');
		this.returnOption = params.returnOption ??= true;
		this.classes = params.classes || [];
		this.settings = ko_helper.safe_observable(params.settings);
		this.settings.subscribe(() => {
			this.render();
		});

		this.value = ko.isObservable(params.value) ? params.value : ko.observable(params.value);
		this.flatpickr;
		this.render();
	}

	render() {
		if (this.flatpickr) {
			this.flatpickr.destroy();
		}

		const settings = new Function("return " + this.settings() + ";")();

		const mergedSettings = {
			mode: "multiple",
			dateFormat: "Y/m/d",
			...settings,
			defaultDate: this.get_default_date(),
			onChange: (selectedDates, dateStr, instance) => {
				this.value(selectedDates.map(date => instance.formatDate(date, "Y/m/d")).join(", "));
			}
		};

		this.flatpickr = window.flatpickr(this.inputElement, mergedSettings);

		this.set_initial_date();

		this.value.subscribe((newValue) => {
			const currentDates = this.flatpickr.selectedDates.map(date => this.flatpickr.formatDate(date, "Y/m/d")).join(", ");
			if (newValue !== currentDates) {
				this.flatpickr.setDate(newValue.split(", "), false);
			}
		});
	}

	get_default_date()
	{
		const value = this.value();
		if (value && typeof value === 'string') {
			return value.split(", "); // Handle string format of dates
		} else if (Array.isArray(value)) {
			return value; // Handle array of dates
		}
		return []; // No dates selected
	}

	// Helper function to set initial date on flatpickr initialization
	set_initial_date()
	{
		const value = this.value();
		if (value && typeof value === 'string') {
			this.flatpickr.setDate(value.split(", "), false);
		} else if (Array.isArray(value)) {
			this.flatpickr.setDate(value, false);
		}
	}
}

export default {
	name: 'ps-multiple-dates-picker',
	viewModel: ComponentViewModel,
	provider: 'ko',
	template: template
};
