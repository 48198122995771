import template from './ko-render-field-object.html';

/**
 * ViewModel for ObjectInputComponent.
 * 
 * @class ObjectInputComponentVM
 * @param {Object} params - The parameters for this ViewModel.
 * @param {string} params.name - The name of the object input component.
 * @param {string} params.label - The label of the object input component.
 * @param {HTMLElement} params.$input - The input element for the object input component.
 * @param {Object} [params.fields_component] - The fields component containing an observable array of fields.
 * @param {Function} [params.fields_component.fields] - A function that returns an array of fields.
 */
class ObjectInputComponentVM
{
	constructor(params)
	{
		this.params = params;

		this.name = ko.observable(params.name);
		this.label = ko.observable(params.label);
		this.$input = params.$input;

		this.fields = ko.observableArray(params.fields_component?.fields() || []);
	}
}


export default {
	name: 'ko-render-field-object',
	viewModel: ObjectInputComponentVM,
	module_type: 'ko',
	template: template
}
